<style scoped>
  .Create_staff_content {
    margin-top: 20px;
    padding: 0 11px 0 17px;
  }

  .Create_staff_content_ul {
    overflow: hidden;
  }

  .Create_staff_content_ul_li {
    overflow: hidden;
  }

  .Create_staff_content_ul_li>div {
    float: left;
  }

  .Create_staff_content_ul_li_img>img {
    width: 53.01px;
    height: 53.01px;
    border-radius: 100%;
  }

  .Create_staff_content_ul_li_namecha {
    margin-left: 10px;
  }

  .rolename {

    font-size: 14px;
    font-weight: 400;
    color: #060606;
    margin-top: 7px;
  }

  .chapter_number {
    margin-top: 3px;

    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }

  .Create_staff_content_ul_li:not(:first-child) {
    margin-top: 25px;
  }

  .Create_staff_del {
    float: right !important;
  }

  .Create_staff_del>img {
    width: 24px;
    height: 24px;
    margin: 14px 0;
  }

  /* 弹窗开始 */
  .menubox {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }

  .isbtn0_btn1 {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    background: #547CFF;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    color: #FFFFFF;
    height: 29px;
    position: relative;
    z-index: 2;
    box-shadow: 0px 0px 0px 7px #fff;
    border: 0px;
  }

  .release_dialong {
    background-color: #fff;
    width: 300px;
    height: 141px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .release_dialong_img {
    height: 31px;
  }

  .release_dialong_img>img {
    width: 60px;
    height: 66px;
    position: absolute;
    top: -35px;
    left: 50%;
    margin-left: -30px;
  }

  .release_dialong_title {
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    color: #202020;
    text-align: center;
    margin-top: 3px;
  }

  .release_dialong_cont {
    text-align: center;
    padding: 0 32px 0 30px;
    margin-top: 15px;
    font-size: 13px;
    font-weight: bold;
    color: #202020;
  }

  .choose {
    border-top: #E7E9EE 1px solid;
    float: left;
    width: 49.83%;
    ;
    text-align: center;
    padding: 11px 0;
    font-size: 15px;
    font-weight: 400;
    color: #547CFF;
  }

  .choose:nth-of-type(1) {
    border-right: #E7E9EE 1px solid;
  }

  .release_dialong_choose {
    margin-top: 10px;
    overflow: hidden;
    position: relative;
    bottom: -22px;
  }

  .choose1 {
    font-size: 15px;
    font-weight: 400;
    color: #9B9EA3;
  }

  /* 弹窗结束 */
  .exit_create {
    margin: 0 22px;
    box-sizing: border-box;
    padding: 10px 0;
    border: 1px solid #547CFF;
    border-radius: 5px;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    color: #547CFF;
    position: fixed;
    bottom: 24px;
    right: 0;
    left: 0;
  }
  .adduser{
    color: #547CFF;
        position: fixed;
        right: 15px;
        top: 12px;
        z-index: 1001;
  }
.pagebgimg{
    position: fixed;
    top: 0;
    z-index: -1;
    width: 100%;height: 100%;
  }
  .pagebgimg>img{
    width: 100%;height: 100%;
  }
</style>
<template>
  <div class="Create_staff">
    <header-title :titleCon="titleCon" titleBtnStr="" @titleLeftBtn="gobookEdit()"
      @titleFnBtn="titleFnListener">
    </header-title>
    <a class="adduser" v-show="adduser" @click="titleFnListener()">添加</a>
    <div class="Create_staff_content">
      <ul class="Create_staff_content_ul">
        <li class="Create_staff_content_ul_li" @click="gotomemchapter(item.userId,item.userName,item.chapterSize)" v-for="item in ugcBookCreatorList"
          :key="item.index">
          <div class="Create_staff_content_ul_li_img">
            <img :src="item.namePath" alt="">
          </div>
          <div class="Create_staff_content_ul_li_namecha">
            <p class="rolename">{{item.userName}}</p>
            <p class="chapter_number">创作了{{item.chapterSize}}章节</p>
          </div>
          <div class="Create_staff_del" v-show="isAuthor == 1&&item.ownFlag == 0">
            <img @click.stop="Create_staff_del(item.userId)" src="../../assets/image/Create_staff_del.png" alt="">
          </div>
        </li>
      </ul>
      <div class="exit_create" v-show="isAuthor == 0" @click="exit_create">退出共创</div>
    </div>
    <!-- 审核通知 -->
    <div v-show="isrelease_dialong">
      <div class="menubox">
        <div class="release_dialong">
          <div class="release_dialong_img">
            <img src="../../assets/image/releaseimage.png" alt="">
          </div>
          <!-- <div class="release_dialong_title">
            审核通知
          </div> -->
          <div class="release_dialong_cont">
            {{release_dialong_cont}}
          </div>
          <div class="release_dialong_choose">
            <div class="choose choose1" @click="iknow">
              确定
            </div>
            <div class="choose" @click="Work_management">
              再想想
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>

<script>
import headerTitle from '../../components/header/header-title-transparent.vue'
export default {
  components: {
    headerTitle
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      titleCon: '共创成员',
      isrelease_dialong: false,
      release_dialong_cont: '',
      isAuthor: null,
      ugcBookCreatorList: null,
      userid: null,
      adduser: false,
      verifyNum: ''
    }
  },
  created () {
    this.verifyNum = this.$route.query.verifyNum
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
    // 加载出成员列表
    this.cylist()
    // 添加的显示或隐藏
    // this.titleFnListenershowhide()
  },
  methods: {

    // 跳转到成员创作
    gotomemchapter (id, name, chapterSize) {
      this.$router.push({
        path: '/memberschapter',
        query: {
          bookId: this.$route.query.bookId,
          cUserId: id,
          rolename: name,
          chaptersize: chapterSize
        }
      })
    },
    // 退出共创
    exit_create () {
      this.isrelease_dialong = true
      this.release_dialong_cont = '真的要退出共创嘛？'
    },
    // 删除成员取消弹窗
    Work_management () {
      this.isrelease_dialong = false
    },
    // 主动退出和被动删除确认弹窗
    iknow () {
      if (this.release_dialong_cont === '真的要退出共创嘛？') {
        this.axios({
          method: 'get',
          url: '/v1/quitBookCreator',
          params: {
            ugcBookId: this.$route.query.bookId
          }
        }).then((res) => {
          if (res.data.head.flag === 0) {
            this.$toast('已退出')
            this.cylist()
            this.isrelease_dialong = false
            this.$router.push({
              path: '/book',
              query: {
                // bookId: this.$route.query.bookId
              }
            })
          }
        })
      } else if (this.release_dialong_cont === '真的要让他退出共创嘛？') {
        this.axios({
          method: 'get',
          url: '/v1/delBookCreator',
          params: {
            ugcBookId: this.$route.query.bookId,
            delUserId: this.userid
          }
        }).then((res) => {
          // console.log(res)
          if (res.data.head.flag === 0) {
            this.$toast('已将对方移出')
            this.cylist()
            this.isrelease_dialong = false
          }
        })
      }
    },
    // 删除成员打开弹窗
    Create_staff_del (id) {
      this.userid = id
      this.isrelease_dialong = true
      this.release_dialong_cont = '真的要让他退出共创嘛？'
    },
    // 返回上一个页面
    gobookEdit () {
      this.$router.go(-1)
    },
    // 右上角添加
    titleFnListener () {
      this.axios({
        method: 'get',
        url: '/v1/ugcCreatorAddFlag',
        params: {
          ugcBookId: this.$route.query.bookId
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          if (res.data.content.flag === 3) {
            this.$toast('共创人员最多只有三人哦，不可再邀请')
          } else {
            window.location.href = '/invitedshare?bookId=' + this.$route.query.bookId + '&sUserId=' + res.data.content.userId
            // this.$router.push({
            //   path: '/invitedshare',
            //   query: {
            //     bookId: this.$route.query.bookId
            //   }
            // })
          }
        }
      })
    },
    // 加载成员列表
    cylist () {
      this.axios({
        method: 'get',
        url: '/v1/ugcBookCreatorList',
        params: {
          ugcBookId: this.$route.query.bookId
        }
      }).then((res) => {
        // console.log(res)
        if (res.data.head.flag === 0) {
          this.isAuthor = res.data.content.isAuthor
          this.ugcBookCreatorList = res.data.content.ugcBookCreatorList
        }
        // console.log(this.isAuthor)
        // console.log(this.isVerify)
        // 控制添加按钮的隐藏显示
        if (this.isAuthor === 1 && res.data.content.ugcBookCreatorList.length < 3) {
          this.adduser = true
        }
      })
    }

  }
}
</script>
